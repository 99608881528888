/* eslint-disable */
<template>
  <div>
    <img
      src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fwww.rover.com%2Fblog%2Fwp-content%2Fuploads%2F2018%2F12%2Fdog-sneeze-1-1024x945.jpg&f=1&nofb=1"
      alt=""
    />
  </div>
</template>

<script>
export default {};
</script>

<style></style>
